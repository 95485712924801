<template>
    <div class="flex flex-wrap justify-center my-10">
        <iframe width="100%" height="480" :src="element.source" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    </div>
</template>

<script>
export default {
    props: ["element"],
    data() {
        return {
        }
    },
}
</script>
