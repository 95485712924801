<template>
  <div class="w-full">
    <vs-button
      @click="toggleMenu"
      :icon-after="true"
      type="border"
      class="w-full"
      style="height: 38px; border: 1px solid rgba(0, 0, 0, 0.2)"
      icon="menu"
      >Filter</vs-button
    >

    <vs-sidebar
      :click-not-close="true"
      class="md:hidden products-sidebar relative spacer"
      parent="body"
      default-index="-1"
      v-model="showSidebar"
      style="z-index: 51000"
    >
      <div class="flex items-center px-4" slot="header">
        <p
          class="font-normal text-white flex-grow text-center"
          style="font-size: 18px"
        >
          Filter Brand name here
        </p>
        <vs-icon
          class="cursor-pointer text-right"
          @click="toggleMenu"
          :style="{ height: '24px', width: '24px' }"
          size="small"
          color="white"
          icon="close"
        />
      </div>

      <div class="mx-6 text-primary2 text-lg pt-4">
        <div
          v-if="categories.length > 0"
          class="text-2xl text-black font-semibold mb-4"
        >
          {{ categories.length === 1 ? "Category" : "Categories" }}
        </div>
        <div
            :key="item._id"
            v-for="(item) in categories"
          >
            <vs-checkbox
              class="ml-0 text-lg font-normal my-2"
              :vs-value="item._id"
              :checked="isSelected(item._id)"
              @click="updateSelectedFilters(item._id)"
              >{{ item.name }}</vs-checkbox
            >
          </div>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { ChevronDownIcon } from "vue-feather-icons";

export default {
  components: {
    ChevronDownIcon,
  },
  data() {
    return {
      showSidebar: false,
      windowWidth: 0,
    };
  },
  props: [
    "categories",
    "isSelected",
    "updateSelectedFilters"
  ],
  methods: {
    toggleMenu() {
      this.showSidebar = !this.showSidebar;
      if (this.showSidebar) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth >= 768) {
        document.body.style.overflow = "auto";
      }
      if (this.windowWidth <= 768 && this.showSidebar == true) {
        document.body.style.overflow = "hidden";
      }
    },
    categoryClick(slug) {
      this.onCategoryClick(slug);
      this.toggleMenu();
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scope lang="scss">
.vs-sidebar {
  z-index: 50000;
}

.categories-button {
  border-radius: 5rem !important;
}

.vs-sidebar--background {
  z-index: 50000;
}

.vs-sidebar--header {
  background-color: #074230;
  width: 100vw;
}

.products-sidebar .vs-sidebar--items {
  overflow: auto;
  width: 100vw;
  padding: 10px 0;
}
.category-col {
  column-count: 2;
  margin-top: 0.5rem;
}
</style>
