<template>
    <div class=" my-10">
        <!-- :paginationEnabled="false"  -->
        <!-- :navigateTo="navigateTo" -->
        <carousel
            :perPage="1"
            :loop="true"
            :autoplay-timeout="2000"
            :autoplay="true"
            ref="carousel"
        >
            <slide
                v-for="(slide, key) in elements"
                :key="key"
            >
                <div>

                    <div
                        
                    >
                        <img class="hidden md:block object-cover w-full imageDesktop" :src="slide.image" />
                        <img class="block: md:hidden w-full object-contain mobileDesktop" :src="slide.image_mobile" />
                    </div>
                    <div class=" flex justify-center">
                        <a  class="underline mb-2 mt-10 font-bold cursor-default" @click="redirectToURL(slide.cta_link)">Click here for more details</a>
                    </div>
                </div>
            </slide>
        </carousel>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';


export default {
    props: ["elements"],
    components: {
        Carousel,
        Slide
    },
    data: () => ({
        navigateTo: 0,
    }),
    methods: {
        redirectToURL(url) {
            if (typeof window !== "undefined") {
                window.open(this.$replaceUrlTemplate(url, this.$getUserType()));
            }
        },
    },
}

</script>
<style scoped>
.imageDesktop {
    max-height: 512px;
}

</style>